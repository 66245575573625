import React, { FC, useEffect, useState } from "react";
import Drawer from "@onnit-js/ui/components/drawer/Drawer";
import Box from "@onnit-js/ui/components/box/Box";
import FlyoutCart from "./FlyoutCart";
import CartBrowserStorageService from "../../services/CartBrowserStorageService";
import { addProducts as addProductsAction, applyCoupon as applyCouponAction } from "../../slices/cartSlice";
import { CartProductConfigs } from "../../interfaces/cart/CartProductConfig";
import BrowserSupportMessage from "../checkout/BrowserSupportMessage";
import ErrorMessageEnum from "../../enums/ErrorMessageEnum";
import { addAppMessage, clearAppMessages } from "../../slices/appSlice";
import PageNameEnum from "../../enums/PageNameEnum";
import { useAppDispatch } from "../../configureStore";

const FlyoutCartContainer: FC<React.PropsWithChildren> = () => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [doShowAddedMsg, setDoShowAddedMsg] = useState(false);

    const openDrawer = (): void => setIsOpen(true);
    const closeDrawer = (): void => {
        setIsOpen(false);
        setDoShowAddedMsg(false);
        dispatch(clearAppMessages());
    };

    // Expose some methods for callers outside of the UI project.
    useEffect(() => {
        const addProducts = async (productConfigs: CartProductConfigs, onAdded?: () => void, onError?: (error: any) => void, listName?: string) => {
            const cartUuid = CartBrowserStorageService.getCartUuid();
            dispatch(clearAppMessages());

            try {
                // A NEW cart will be created if cartUuid is undefined.
                await dispatch(addProductsAction(productConfigs, cartUuid, listName));

                // Some callers will be outside of the cart UI app.
                // Promises aren't fully-supported in ES5 (damn you, IE 11).
                if (onAdded) {
                    onAdded();
                }

                setDoShowAddedMsg(true);
             } catch (error: any) {
                if (onError) {
                    onError(error);
                }

                dispatch(addAppMessage({ message: error?.response?.data?.error_message ?? ErrorMessageEnum.GENERIC, pagesVisible: [PageNameEnum.CART] }));
                setDoShowAddedMsg(false);
                setIsOpen(true);

                // Re-throw the error so callers utilizing the returned Promise can handle it.
                throw error;
            }
        };

        const applyCoupon = async (couponCode: string, onAdded?: () => void, onError?: (error: any) => void) => {
            const cartUuid = CartBrowserStorageService.getCartUuid();
            dispatch(clearAppMessages());

            try {
                await dispatch(applyCouponAction({ code: couponCode, cart_uuid: cartUuid ?? null }));

                if (onAdded) {
                    onAdded();
                }
             } catch (error: any) {
                if (onError) {
                    onError(error);
                }

                dispatch(addAppMessage({ message: error?.response?.data?.error_message ?? ErrorMessageEnum.GENERIC, pagesVisible: [PageNameEnum.CART] }));
                setIsOpen(true);

                // Re-throw the error so callers utilizing the returned Promise can handle it.
                throw error;
            }
        };

        window.ONNIT = window.ONNIT || {};
        window.ONNIT.cart = {
            openDrawer,
            closeDrawer,
            addProducts,
            applyCoupon
        };

        return () => {
            if (window.ONNIT && window.ONNIT.cart) {
                delete window.ONNIT.cart;
            }
        };
    });

    return (
        <Drawer isOpen={isOpen} onOverlayClick={closeDrawer} width={["356px", "370px", "400px"]} ariaLabel="Shopping Bag">
            <Box display="flex" flexDirection="column" height="100%" style={{ overflow: "hidden" }}>
                <BrowserSupportMessage />
                <FlyoutCart
                    doShowAddedMsg={doShowAddedMsg}
                    closeDrawer={closeDrawer}
                />
            </Box>
        </Drawer>
    );
};

export default FlyoutCartContainer;
