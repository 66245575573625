import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import { Text } from "@onnit-js/ui/components/text";

interface Props {
    closeDrawer: () => void;
}

const EmptyCart: React.FC<React.PropsWithChildren<Props>> = ({ closeDrawer, children }) => (
    <Box p={3}>
        {children}
        <Text as="p" fontWeight="light" fontSize={4} mb={3} textAlign="center">
            You have no items in your bag.
        </Text>
        <Button display="block" width="100%" my={2} onClick={() => closeDrawer()}>
            Keep shopping
        </Button>
    </Box>
);
export default EmptyCart;
