import React from "react";
import ReactDOM from "react-dom";
import CartBrowserStorageService from "../../services/CartBrowserStorageService";
import QuantityBubble from "../checkout/cart/product/QuantityBubble";
import { useAppSelector } from "../../configureStore";

interface Props {
    selector: string;
}

const CartQuantityBubble = ({ selector }: Props) => {
    const cart = useAppSelector((state) => state.cart);

    const getQuantitySum = (): number => {
        // If cart has been set in GlobalState, consider it the source of truth.
        if (cart) {
            return cart.quantity_sum;
        }

        const cartUuid = CartBrowserStorageService.getCartUuid();

        if (!cartUuid) {
            // Cart UUID cookie isn't set. Ensure quantity sum is cleared from localStorage so it's not a stale value.
            CartBrowserStorageService.removeQuantitySum();
            return 0;
        }

        try {
            return CartBrowserStorageService.getQuantitySum() || 0;
         } catch (error: any) {
            console.error("Failed to get cart quantity sum from localStorage.", error);
            return 0;
        }
    };

    const node = document.querySelector(selector);
    const sum = getQuantitySum();

    if (!node || !sum) {
        return null;
    }

    const bubble = <QuantityBubble style={{ top: "calc(50% - 16px)", right: -4, fontSize: 10, padding: "4px 6px 1px 6px" }}>{sum}</QuantityBubble>;

    return ReactDOM.createPortal(bubble, node);
};

export default CartQuantityBubble;
