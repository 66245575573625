import React from "react";
import { FaCheck } from "react-icons/fa";
import { Box, BoxProps } from "../box/Box";
import Icon from "./Icon";

interface Props extends BoxProps {
    checked: boolean;
    color?: string;
}

const CheckCircleIcon = ({ checked = false, color = "sunwashGold", ...rest }: Props) => (
    <Box
        border="2px solid"
        borderColor={checked ? color : "grays.4"}
        borderRadius="50%"
        width="20px"
        height="20px"
        pl="3px"
        {...rest}
    >
        {checked && <Icon icon={FaCheck} color={checked ? color : "grays.4"} size={11} verticalAlign="baseline" />}
    </Box>
);

export default CheckCircleIcon;
