import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CartClient from "../clients/cart/CartClient";
import GetCartConfig from "../interfaces/cart/GetCartConfig";
import ThunkAction from "../interfaces/ThunkAction";
import Cart from "../interfaces/cart/Cart";
import UpdateCartCustomerResponse from "../interfaces/cart/UpdateCartCustomerResponse";
import eventEmitter from "../events/eventEmitter";
import UserSessionUpdatedEvent from "../events/events/UserSessionUpdatedEvent";
import CartPreferences from "../interfaces/cart/CartPreferences";
import CartAddressClient from "../clients/cart/CartAddressClient";
import UpdateAddressIdsConfig from "../interfaces/address/UpdateAddressIdsConfig";
import CartCouponClient from "../clients/cart/CartCouponClient";
import { EnteredCouponConfig } from "../interfaces/coupon/EnteredCouponConfig";
import GlobalState from "../interfaces/GlobalState";
import CartCouponEnteredEvent from "../events/events/CartCouponEnteredEvent";
import CartCouponAppliedEvent from "../events/events/CartCouponAppliedEvent";
import { CartCouponConfig } from "../interfaces/coupon/CartCouponConfig";
import { clearShippingQuotes } from "./shippingQuotesSlice";
import CartCouponDeniedEvent from "../events/events/CartCouponDeniedEvent";
import HttpUtil from "../utils/HttpUtil";
import Coupon from "../interfaces/coupon/Coupon";
import CartCouponRemovedEvent from "../events/events/CartCouponRemovedEvent";
import CartProductClient from "../clients/cart/CartProductClient";
import CartProductConfig, { CartProductConfigs } from "../interfaces/cart/CartProductConfig";
import CartProductUtil from "../utils/CartProductUtil";
import CartProductsAddedEvent from "../events/events/CartProductsAddedEvent";
import CartProductRemovedEvent from "../events/events/CartProductRemovedEvent";
import ThunkDispatch from "../interfaces/ThunkDispatch";
import CartBrowserStorageService from "../services/CartBrowserStorageService";
import { setIsLoading } from "./appSlice";

const initialState: Cart | null = null;

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setCart: (state: Cart | null, action: PayloadAction<Cart | null>) => {
            state = action.payload;
            // When a state value has an initial state of null, then gets reassigned to a
            // different type.  We must return the state from the reducer function. I think this is an Immer issue.
            return state as any;
        },
        setCartCustomerEmail(state: Cart | null, action: PayloadAction<string>) {
            if (state) {
                state.customer_email = action.payload;
            }
        },
        setCartPreferencesPartially(state: Cart | null, action: PayloadAction<CartPreferences>) {
            if (state) {
                const existingPreferences = state.preferences ?? {};
                state.preferences = {
                    ...existingPreferences,
                    ...action.payload,
                };
            }
        },
        // We need to clear addresses on the cart in memory
        // to manage Pay Now button disabled state.
        clearCartShippingAddress(state: Cart | null) {
            if (state) {
                state.shipping_address = null;
            }
        },
        clearCartBillingAddress(state: Cart | null) {
            if (state) {
                state.billing_address = null;
            }
        }
    }
});

export const {
    setCartCustomerEmail,
    setCartPreferencesPartially,
    clearCartShippingAddress,
    clearCartBillingAddress,
} = cartSlice.actions;

export default cartSlice;

// ------------------------- [ Thunks ] -------------------------
const cartClient = new CartClient();

export const setCart = createAction("cart/setCart", (cart: Cart | null) => {
    try {
        if (cart) {
            CartBrowserStorageService.setCartUuid(cart.cart_uuid);
            CartBrowserStorageService.setQuantitySum(cart.quantity_sum);
        } else {
            CartBrowserStorageService.removeCartValues();
        }
     } catch (error: any) {
        console.error("Storage: Failed to modify cart UUID and/or quantity sum.", error);
    }
    return {
        payload: cart
    };
});

export const clearCart = (): ThunkAction<void> => ((dispatch: ThunkDispatch) => {
    dispatch(setCart(null));
});

export const loadCart = (cartUuid: string, cartConfig: GetCartConfig = {
    do_validate: true,
    do_remove_erroneous_products: true,
    do_validate_shipping_restrictions: true,
    do_calculate_tax: true,
}): ThunkAction<Promise<Cart>> => (
    async (dispatch, getState) => {
        let config = cartConfig;
        const currentCart = getState().cart;
        dispatch(setIsLoading(true));

        if (!currentCart?.shipping_address) {
            config = {
                do_validate: false,
                do_remove_erroneous_products: false,
                do_calculate_tax: false,
                do_validate_shipping_restrictions: false,
            };
        }

        const response = await cartClient.getCart(cartUuid, config);
        dispatch(setIsLoading(false));
        const cart = response.data;
        dispatch(setCart(cart));

        console.debug("Successfully loaded cart.", cartUuid, config);

        return response.data;
    }
);

export const updateEmail = (cart: Cart, email: string): ThunkAction<Promise<UpdateCartCustomerResponse>> => (
    async (dispatch) => {
        const { data } = await cartClient.updateCustomerEmail(cart.cart_uuid, email);
        dispatch(setCartCustomerEmail(email));

        // For performance, this endpoint doesn't return a full cart object, so push this cart object in memory containing the new email values.
        eventEmitter.emit(new UserSessionUpdatedEvent({
            ...cart,
            customer_email: data.customer_email,
            customer_email_hash: data.customer_email_hash,
        }));

        return data;
    }
);

// ------------------------- [ Cart Products ] -------------------------
const cartProductClient = new CartProductClient();

export const addProducts = (
    productConfigs: CartProductConfigs,
    cartUuid?: string,
    listName?: string,
): ThunkAction<Promise<Cart>> => async (dispatch) => {
    const response = await cartProductClient.addProducts({
        productConfigs,
        cartUuid,
        trackUri: window.location.pathname,
        clientCountryCode: window.onnit_context?.client?.country_code,
    });
    const cart = response.data;
    const cartProductsAdded = CartProductUtil.getProducts(cart, productConfigs);

    // Clear shipping quotes in memory to force re-fetch in cartListener
    dispatch(clearShippingQuotes());

    dispatch(setCart(cart));

    eventEmitter.emit(
        new CartProductsAddedEvent(cartProductsAdded, cart, listName),
    );

    return cart;
};

export const addProduct = (productConfig: CartProductConfig, cartUuid?: string, listName?: string): ThunkAction<Promise<Cart>> => (
    addProducts([productConfig], cartUuid, listName)
);

const onProductsUpdated = (cartBeforeUpdate: Cart | null, cartAfterUpdate: Cart, configs: CartProductConfigs): void => {
    if (!cartBeforeUpdate) {
        return;
    }
    configs.forEach((config) => {
        // We removed the product. Emit the event.
        if (config.quantity === 0) {
            const cartProduct = CartProductUtil.getProduct(cartBeforeUpdate, config);
            if (cartProduct) {
                eventEmitter.emit(new CartProductRemovedEvent(cartProduct, cartAfterUpdate));
            } else {
                console.warn("Event: Cannot emit `CartProductRemovedEvent` because the removed product couldn't be determined.", config);
            }
        }
    });
};

export const updateProduct = (
    productConfig: CartProductConfig,
    cartUuid: string,
): ThunkAction<Promise<Cart>> => async (dispatch, getState: () => GlobalState) => {
    // We need to grab the product from the cart in state BEFORE we update
    // because the product won't exist in the cart AFTER the update if the product was removed.
    const cartBeforeUpdate = getState().cart;

    const response = await cartProductClient.updateProduct(productConfig, cartUuid);
    const cart = response.data;

    // Clear shipping quotes to force re-fetch in listener.
    dispatch(clearShippingQuotes());
    dispatch(setCart(cart));

    onProductsUpdated(cartBeforeUpdate, cart, [productConfig]);

    return cart;
};

export const partiallyUpdateProducts = (
    configs: CartProductConfigs,
    cartUuid: string,
    calculateTax = false,
): ThunkAction<Promise<Cart>> => async (dispatch, getState: () => GlobalState) => {
    const cartBeforeUpdate = getState().cart;
    const response = await cartProductClient.partiallyUpdateProducts(configs, cartUuid, calculateTax);
    const cart = response.data;

    // Clear shipping quotes to force re-fetch in listener.
    dispatch(clearShippingQuotes());
    dispatch(setCart(cart));

    onProductsUpdated(cartBeforeUpdate, cart, configs);

    return cart;
};

// ------------------------- [ Cart Coupons ] -------------------------
const cartCouponClient = new CartCouponClient();

export const applyCoupon = (config: EnteredCouponConfig): ThunkAction<Promise<Cart>> => (
    async (dispatch, getState: () => GlobalState) => {
        eventEmitter.emit(new CartCouponEnteredEvent(config));
        try {
            const { cart } = getState();
            const response = await cartCouponClient.applyCoupon(config, {
                do_calculate_tax: !!cart?.shipping_address && cart.totals.shipping !== null,
                client_country_code: window.onnit_context?.client?.country_code,
            });
            const newCart = response.data;
            dispatch(setCart(response.data));

            eventEmitter.emit(new CartCouponAppliedEvent((config as CartCouponConfig)));

            return newCart;
         } catch (error: any) {
            const errorMessage = "Failed to apply coupon.";
            eventEmitter.emit(new CartCouponDeniedEvent(config));
            HttpUtil.logErroneousRequest(errorMessage, error);

            throw error;
        }
    }
);

export const removeCoupon = (cartUuid: string, coupon: Coupon): ThunkAction<Promise<Cart>> => (
    async (dispatch) => {
        const { coupon_id, code } = coupon;
        try {
            const response = await cartCouponClient.removeCoupon(cartUuid, coupon_id);
            const cart = response.data;
            dispatch(setCart(response.data));

            eventEmitter.emit(new CartCouponRemovedEvent({ cart_uuid: cartUuid, code }));

            return cart;
         } catch (error: any) {
            const errorMessage = "Failed to remove coupon.";
            HttpUtil.logErroneousRequest(errorMessage, error);

            throw error;
        }
    }
);

// ------------------------- [ Cart Addresses ] -------------------------
const cartAddressClient = new CartAddressClient();

export const updateCartAddressIds = (cartUuid: string, config: UpdateAddressIdsConfig): ThunkAction<Promise<Cart>> => (
    async (dispatch) => {
        dispatch(setIsLoading(true));
        // This request returns a cart but it currently does not validate the cart.
        const response = await cartAddressClient.updateCartAddressIds(cartUuid, config);
        let cart = response.data;

        // If the shipping address changed, loading new quotes will revalidate the cart, so
        // we don't need to revalidate the cart products since the new shipping quotes fetch will do this for us.
        if (config.shipping_address_id) {
            console.debug("Shipping address changed.");
            try {
                const response = await cartClient.getCart(cartUuid, {
                    do_validate: false,
                    do_validate_shipping_restrictions: false,
                    do_remove_erroneous_products: false,
                    do_calculate_tax: false,
                });
                // Clear shipping quotes in memory when the address changes.
                // We can't rely on comparing previousCart and current cart shipping
                // address ids in the cartEffect to fetch new quotes. Since we had
                // to reload the cart, the address ids will be the same
                // by the time the effect runs after the setCart is called by the caller of this method.
                cart = response.data;
                dispatch(clearShippingQuotes());
             } catch (error: any) {
                console.log(error);
            }
        }

        dispatch(setIsLoading(false));

        return cart;
    }
);
