import React from "react";
import { Provider } from "react-redux";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Store from "../../configureStore";
import FlyoutCartContainer from "./FlyoutCartContainer";
import CartQuantityBubble from "./CartQuantityBubble";

const FlyoutAppContainer = () => (
    <ThemeProvider theme={theme}>
        <Provider store={Store}>
            <GlobalStyle />
            <CartQuantityBubble selector="#cart-quantity-sum" />
            <FlyoutCartContainer />
        </Provider>
    </ThemeProvider>
);

export default FlyoutAppContainer;
