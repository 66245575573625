import ApplePay, { ApplePayPayload } from "braintree-web/apple-pay";
import { Client as ClientInterface } from "braintree-web/client";
import BrowserSupportService from "../BrowserSupportService";
import ApplePayPayloadFactory from "../applepay/ApplePayPayloadFactory";
import { Totals } from "./types/expressCheckout";

type ApplePayValidateMerchantEvent = ApplePayJS.ApplePayValidateMerchantEvent;
type ApplePayPaymentRequest = ApplePayJS.ApplePayPaymentRequest;

export interface PaymentRequestConfig {
    requireShippingAddress?: boolean;
    requireBillingAddress?: boolean;
    totals: Totals;
}

export const MERCHANT_NAME = "Onnit";

class BraintreeApplePayManager {
    /**
     * @see https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history
     */
    public static readonly APPLE_PAY_VERSION: number = 6;
    private readonly REQUIRED_CONTACT_FIELDS: string[] = ["email", "name", "phone", "postalAddress"];
    private readonly btClient: ClientInterface;
    private readonly payloadFactory: ApplePayPayloadFactory;
    private btApplePay?: ApplePay.ApplePay;

    constructor(btClient: ClientInterface, payloadFactory: ApplePayPayloadFactory) {
        this.btClient = btClient;
        this.payloadFactory = payloadFactory;
    }

    createPaymentRequest = async (config: PaymentRequestConfig): Promise<ApplePayPaymentRequest> => {
        this.btApplePay = await this.createApplePayInstance();
        const { totals } = config;
        const paymentRequest = this.btApplePay.createPaymentRequest({
            lineItems: this.payloadFactory.makeLines(totals),
            total: this.payloadFactory.makeTotal(totals, MERCHANT_NAME),
        });
        if (config.requireShippingAddress) {
            paymentRequest.requiredShippingContactFields = this.REQUIRED_CONTACT_FIELDS;
        }
        if (config.requireBillingAddress) {
            paymentRequest.requiredBillingContactFields = this.REQUIRED_CONTACT_FIELDS;
        }

        return paymentRequest as ApplePayPaymentRequest;
    };

    startSession = (request: ApplePayPaymentRequest): ApplePaySession => {
        if (typeof ApplePaySession === "undefined") {
            throw new Error("Apple Pay is not supported.");
        }
        const session = new ApplePaySession(BraintreeApplePayManager.APPLE_PAY_VERSION, request);

        // Begin the merchant validation process.
        session.begin();
        session.onvalidatemerchant = async (event) => this.onValidateMerchant(event, session);

        return session;
    };

    private onValidateMerchant = async (event: ApplePayValidateMerchantEvent, session: ApplePaySession) => {
        console.debug("Apple Pay: Handling 'onvalidatemerchant'.");
        try {
            if (!this.btApplePay) {
                throw new Error("Braintree ApplePay instance is unexpectedly null.");
            }

            // Pass Apple's validation data to Braintree to retrieve the opaque merchant session specific to our merchant account.
            const merchantSession = await this.btApplePay.performValidation({
                validationURL: event.validationURL,
                displayName: MERCHANT_NAME,
            });

            // Enable the payment sheet.
            session.completeMerchantValidation(merchantSession);
            console.debug("Apple Pay: Handled 'onvalidatemerchant'.");
        } catch (error: any) {
            session.abort();
            console.error("Apple Pay: Failed merchant validation.", error);
            throw error;
        }
    };

    tokenize = (token: object): Promise<ApplePayPayload> => {
        if (!this.btApplePay) {
            throw new Error("Braintree Apple Pay instance is undefined.");
        }

        return this.btApplePay.tokenize({ token });
    };

    private createApplePayInstance = async (): Promise<ApplePay.ApplePay> => {
        if (!BrowserSupportService.supportsApplePay()) {
            throw new Error(`This device either does not support Apple Pay or does not support version ${BraintreeApplePayManager.APPLE_PAY_VERSION}.`);
        }
        try {
            const btApplePay = await ApplePay.create({ client: this.btClient });
            if (btApplePay.merchantIdentifier === undefined) {
                throw new Error("Braintree merchant identifier is absent.");
            }
            console.debug("Apple Pay: Instance created.");
            return btApplePay;
        } catch (error: any) {
            console.error("Error creating Apple Pay instance.", error);
            throw new Error(error);
        }
    };
}

export default BraintreeApplePayManager;
