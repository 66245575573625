import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import CartShippingClient from "../clients/cart/CartShippingClient";
import ThunkAction from "../interfaces/ThunkAction";
import { ShippingQuoteGroups } from "../interfaces/shipping-quote/ShippingQuoteGroup";
import ShippingQuoteSelectedAssociation from "../interfaces/shipping-quote/ShippingQuoteSelectedAssociation";
import Cart from "../interfaces/cart/Cart";
import { setIsLoading as setAppLoading } from "./appSlice";
import { setCart } from "./cartSlice";
import ShippingQuote from "../interfaces/shipping-quote/ShippingQuote";
import HttpUtil from "../utils/HttpUtil";
import eventEmitter from "../events/eventEmitter";
import PageSubmittedEvent from "../events/events/PageSubmittedEvent";
import RouteEnum from "../enums/RouteEnum";

export interface SelectedMap {
    [vendorId: number]: string; // Shipping method ID
}

export interface ShippingQuotesState {
    isLoading: boolean,
    isValid: boolean;
    selectedQuotesByVendor: SelectedMap | null;
    quoteGroups: ShippingQuoteGroups;
}

const initialState: ShippingQuotesState = {
    isLoading: false,
    isValid: true,
    selectedQuotesByVendor: null,
    quoteGroups: [],
};

const shippingQuotesSlice = createSlice({
    name: "shippingQuotes",
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setSelectedMethodId(state, action: PayloadAction<{ vendorId: number, shippingMethodId: string, cartQuotesSelected: SelectedMap }>) {
            const { vendorId, shippingMethodId, cartQuotesSelected } = action.payload;
            state.selectedQuotesByVendor = {
                ...state.selectedQuotesByVendor,
                [vendorId]: shippingMethodId,
            };
            // Mark invalid if selection changed.
            // Require save button click to enable the Pay Now button.
            state.isValid = isEqual(state.selectedQuotesByVendor, cartQuotesSelected);
        },
        setSelectedShippingQuotes(state, action: PayloadAction<SelectedMap>) {
            state.selectedQuotesByVendor = action.payload;
            state.isValid = true;
        },
        setShippingQuotes(state, action: PayloadAction<ShippingQuoteGroups>) {
            state.quoteGroups = action.payload;
            state.selectedQuotesByVendor = null;
        },
        clearShippingQuotes(state) {
            state.quoteGroups = [];
            state.selectedQuotesByVendor = null;
            state.isValid = false;
        },
    }
});

export const {
    setIsLoading,
    setSelectedMethodId,
    setShippingQuotes,
    clearShippingQuotes,
    setSelectedShippingQuotes,
} = shippingQuotesSlice.actions;

export default shippingQuotesSlice;

// ------------------------- [ Utils ] -------------------------
const selectedMapToAssociations = (selectedMap: SelectedMap): ShippingQuoteSelectedAssociation[] => Object.entries(selectedMap)
        .map((value) => {
            const [vendorId, shippingMethodId] = value;
            return ({
                vendor_id: Number(vendorId),
                shipping_method_id: shippingMethodId
            });
        });

const getSelectedMap = (quoteGroups: ShippingQuoteGroups): SelectedMap => (
    quoteGroups.reduce((selectedMap: SelectedMap, quoteGroup) => {
        quoteGroup.quotes.forEach((quote) => {
            if (quote.is_selected) {
                selectedMap[quoteGroup.vendor_id] = quote.shipping_method.shipping_method_id;
            }
        });

        return selectedMap;
    }, {})
);

// ------------------------- [ Thunks ] -------------------------
const cartShippingClient = new CartShippingClient();

export const updateShippingQuotesSelected = (cartUuid: string, selected: SelectedMap): ThunkAction<Promise<Cart>> => (
    async (dispatch) => {
        // Update cart on server
        const associations = selectedMapToAssociations(selected);
        const response = await cartShippingClient.updateShippingQuotesSelected(cartUuid, associations);
        const cart = response.data;

        // Save to local state
        dispatch(setSelectedShippingQuotes(selected));

        // Track event
        const selectedMethodNames = cart?.shipping_quotes_selected.map(({ shipping_method }) => `${shipping_method.courier_name} ${shipping_method.service_name}`);
        eventEmitter.emit(
            new PageSubmittedEvent(
                RouteEnum.SHIPPING_SPEED,
                cart,
                selectedMethodNames,
            ),
        );

        return cart;
    }
);

/**
 * We want to always select the quotes marked selected from the API.
 * Shipping restrictions/validations can be handled in the API when fetching shipping quotes.
 */
export const setDefaultQuotesSelected = (quoteGroups: ShippingQuoteGroups): ThunkAction<Promise<void>> => (
    async (dispatch, getState) => {
        const { cart } = getState();
        if (!cart) {
            return;
        }

        const apiShippingQuotesSelected = getSelectedMap(quoteGroups);
        const cartSelectionMap = cart.shipping_quotes_selected.reduce((acc: SelectedMap, quote: ShippingQuote) => {
            acc[quote.vendor_id] = quote.shipping_method.shipping_method_id;
            return acc;
        }, {});

        // If the quoteGroups selected match cart selected, skip saving to cart but
        // save to redux state for ShippingQuotesForm component state
        if (isEqual(cartSelectionMap, apiShippingQuotesSelected)) {
            dispatch(setSelectedShippingQuotes(cartSelectionMap));
            return;
        }

        try {
            // Save selections on the cart
            dispatch(setAppLoading(true));
            const newCart = await dispatch(updateShippingQuotesSelected(cart.cart_uuid, apiShippingQuotesSelected));
            console.debug("Setting selected shipping methods to quotes returned from the API.");
            dispatch(setCart(newCart));
         } catch (error: any) {
            HttpUtil.logErroneousRequest("Failed to set default shipping quote selection on cart.", error);
        } finally {
            dispatch(setAppLoading(false));
        }
    }
);

export const loadShippingQuotes = (cartUuid: string): ThunkAction<Promise<void>> => (
    async (dispatch, getState) => {
        if (getState().shippingQuotes.isLoading) {
            // Skip if already fetching
            return;
        }
        dispatch(setAppLoading(true));
        dispatch(setIsLoading(true));
        try {
            console.debug("Fetching shipping quotes");
            // Verify all products, shipping restrictions, and tax is valid when fetching shipping quotes.
            // This is done here so we don't have to loadCart with validation repeatedly
            const response = await cartShippingClient.getShippingQuotes(cartUuid, {
                do_validate: true,
                do_remove_erroneous_products: true,
                do_validate_shipping_restrictions: true,
                do_calculate_tax: true,
            });
            const quoteGroups = response.data.shipping_quote_groups;
            const newCart = response.data.cart;
            dispatch(setShippingQuotes(quoteGroups));
            dispatch(setCart(newCart));
            if (quoteGroups.length) {
                await dispatch(setDefaultQuotesSelected(quoteGroups));
            }
         } catch (error: any) {
            HttpUtil.logErroneousRequest("Failed to fetch shipping quotes", error);
        } finally {
            dispatch(setIsLoading(false));
            dispatch(setAppLoading(false));
        }
    }
);
