import React, { FC, useEffect, useState } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { H1 } from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import OverlayProgress from "@onnit-js/ui/components/progress/OverlayProgress";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { IoIosClose } from "react-icons/io";
import CartBrowserStorageService from "../../services/CartBrowserStorageService";
import { loadCart } from "../../slices/cartSlice";
import Cart from "../checkout/cart/Cart";
import AddedMessage from "./AddedMessage";
import EmptyCart from "./EmptyCart";
import eventEmitter from "../../events/eventEmitter";
import CartViewedEvent from "../../events/events/CartViewedEvent";
import RewardPointValueText from "../checkout/cart/RewardPointValueText";
import CartMessagesContainer from "../checkout/CartMessagesContainer";
import PageNameEnum from "../../enums/PageNameEnum";
import ValidationContainer from "../checkout/cart/validation/ValidationContainer";

import { useAppDispatch, useAppSelector } from "../../configureStore";

interface Props {
    doShowAddedMsg: boolean;
    closeDrawer: () => void;
}

const FlyoutCart: FC<React.PropsWithChildren<Props>> = ({ doShowAddedMsg, closeDrawer }) => {
    const dispatch = useAppDispatch();
    const cart = useAppSelector((state) => state.cart);
    const isLoading = useAppSelector((state) => state.app.isLoading);

    const containerRef = React.createRef<HTMLDivElement>();
    const [hasViewed, setHasViewed] = useState(false);
    const cartHasProducts = cart && cart.products.length > 0;

    useEffect(() => {
        const cartUuid = CartBrowserStorageService.getCartUuid();
        // Nothing to load if we already have a cart OR don't have a cart UUID cookie.
        if (cart || !cartUuid) {
            setHasViewed(true);
            return;
        }

        dispatch(loadCart(cartUuid))
            .catch((error:any) => {
                console.error("Failed to initialize FlyoutCart.", error);

                // Force the user to get a new cart in case there is something wrong with this one.
                CartBrowserStorageService.removeCartValues();
            })
            .finally(() => {
                setHasViewed(true);
            });
    }, [cart, dispatch]);

    useEffect(() => {
        if (hasViewed && cart) {
            eventEmitter.emit(new CartViewedEvent(cart));
        }
    }, [cart, hasViewed]);

    useEffect(() => {
        // Focus container when it mounts (drawer opens).
        const { current } = containerRef;
        current?.focus();

        return () => {
            current?.blur();
        };
    }, [containerRef]);

    return (
        <>
            <Box
                flex="1"
                style={{ overflow: "auto", position: "relative" }}
                pb={3}
                onKeyUp={(event) => {
                event.stopPropagation();
                if (event.key === "Escape") {
                    closeDrawer();
                }
            }}
            >
                {isLoading && (
                <OverlayProgress
                    width={["90%", "370px", "400px"]}
                    style={{ right: 0, left: "initial" }}
                />
            )}

                <button
                    aria-label="Close"
                    type="button"
                    onClick={() => closeDrawer()}
                    style={{ position: "absolute", right: 0, background: "none", border: "none", cursor: "pointer" }}
                >
                    <Icon
                        icon={IoIosClose}
                        size={44}
                    />
                </button>

                {doShowAddedMsg && cartHasProducts ? (
                    <AddedMessage />
            ) : (
                <H1
                    fontSize={[6, 8]}
                    p={2}
                    my={6}
                    textAlign="center"
                    fontStyle="italic"
                    fontWeight="heavy"
                >
                    Your bag
                </H1>
            )}
                {cart && cartHasProducts ? (
                    <>
                        <Box px={3} pb={3}>
                            <CartMessagesContainer pageVisible={PageNameEnum.CART} />
                            <ValidationContainer validation={cart.validation} />
                            <Cart cart={cart} showHeader={false} showIdMe={false} />
                        </Box>
                        <RewardPointValueText value={cart.totals.adjusted_price} />
                    </>
            ) : (!isLoading && (
                <EmptyCart closeDrawer={closeDrawer}>
                    <CartMessagesContainer pageVisible={PageNameEnum.CART} />
                    {cart && (
                        // Show validation messages in case all products were auto-removed from the cart.
                        (<ValidationContainer validation={cart.validation} />)
                    )}
                </EmptyCart>
            ))}
            </Box>
            {cartHasProducts && (
            <Box p={3} bg="sunwashGold">
                <Button display="block" width="100%" el="a" href="/cart/checkout">
                    Continue to checkout
                </Button>
            </Box>
        )}
        </>
);
};

export default FlyoutCart;
