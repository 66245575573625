import {
    combineSlices,
    configureStore,
    createListenerMiddleware,
    ListenerEffectAPI,
    TypedStartListening
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { TypedStopListening } from "@reduxjs/toolkit/src/listenerMiddleware/types";
import config from "./config";
import addressSlice from "./slices/addressSlice";
import appSlice from "./slices/appSlice";
import authSlice from "./slices/authSlice";
import cartSlice from "./slices/cartSlice";
import orderSlice from "./slices/orderSlice";
import paymentSlice from "./slices/paymentSlice";
import postUpsellSlice from "./slices/postUpsellSlice";
import shippingQuotesSlice from "./slices/shippingQuotesSlice";
import upsellsSlice from "./slices/upsellsSlice";
import ThunkDispatch from "./interfaces/ThunkDispatch";

export const listenerMiddlewareInstance = createListenerMiddleware({
    onError: () => console.error,
});

export const store = configureStore({
    devTools: config.NODE_ENV === "development",
    reducer: combineSlices(
        addressSlice,
        appSlice,
        authSlice,
        cartSlice,
        orderSlice,
        paymentSlice,
        postUpsellSlice,
        shippingQuotesSlice,
        upsellsSlice
    ),
    // Add the listener middleware to the store.
    // NOTE: Since this can receive actions with functions inside,
    // it should go before the serializability check middleware
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddlewareInstance.middleware),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Use is listener effect callbacks
export type AppListenerEffectAPI = ListenerEffectAPI<RootState, ThunkDispatch>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => ThunkDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppStartListening = TypedStartListening<RootState, ThunkDispatch>;
export type AppStopListening = TypedStopListening<RootState, ThunkDispatch>;

export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening;
export const stopAppListening = listenerMiddlewareInstance.stopListening as AppStopListening;
