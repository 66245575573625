import React from "react";
import { createRoot } from "react-dom/client";
import FlyoutAppContainer from "./components/flyout/FlyoutAppContainer";
import CartBrowserStorageService from "./services/CartBrowserStorageService";

CartBrowserStorageService.startOnnitVisitorSession();

const container = document.getElementById("cart-flyout-root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // CreateRoot(container!) if you use TypeScript

if (container) {
    root.render(<FlyoutAppContainer />);
} else {
    console.error("Failed to mount cart flyout because a root element wasn't defined.");
}
