import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AppState, { BootstrapStatus, LoadingKey } from "../interfaces/AppState";
import CartMessage from "../interfaces/cart/CartMessage";
import CartMessageLevelEnum from "../enums/CartMessageLevelEnum";
import PageNameEnum from "../enums/PageNameEnum";
import GetCartConfig from "../interfaces/cart/GetCartConfig";
import ThunkAction from "../interfaces/ThunkAction";
import Cart from "../interfaces/cart/Cart";
import ThunkDispatch from "../interfaces/ThunkDispatch";
import GlobalState from "../interfaces/GlobalState";
import eventEmitter from "../events/eventEmitter";
import UserSessionUpdatedEvent from "../events/events/UserSessionUpdatedEvent";
import { loadCart } from "./cartSlice";
import { loadAddresses } from "./addressSlice";
import { loadCustomerPaymentMethods } from "./paymentSlice";

const initialState: AppState = {
    isLoading: false,
    loading: {
        [LoadingKey.bootstrap]: false,
        [LoadingKey.braintree]: false,
        [LoadingKey.cart]: false,
        [LoadingKey.other]: false,
        [LoadingKey.paymentGateways]: false,
        [LoadingKey.shipping]: false,
        [LoadingKey.customerPaymentMethods]: false,
    },
    bootstrapStatus: BootstrapStatus.IDLE,
    messages: [],
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        /**
         * @deprecated setIsLoading()
         * Please use setLoading() to avoid race conditions.
         * All setIsLoading() will use the `other` LoadingKey attribute.
         */
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.loading[LoadingKey.other] = action.payload;
            state.isLoading = Object.values(state.loading).some((loading) => loading);
        },
        setLoading(state, action: PayloadAction<{ key: LoadingKey, isLoading: boolean }>) {
            const { key, isLoading } = action.payload;
            state.loading[key] = isLoading;
            state.isLoading = Object.values(state.loading).some((loading) => loading);
        },
        setBootstrapStatus(state, action: PayloadAction<BootstrapStatus>) {
            state.bootstrapStatus = action.payload;
        },
        setAppMessages(state, action: PayloadAction<CartMessage[]>) {
            state.messages = action.payload;
        },
        clearAppMessages(state) {
            state.messages = [];
        },
        addAppMessage(state, action: PayloadAction<{
            message: string,
            pagesVisible: PageNameEnum[],
            level?: CartMessageLevelEnum
        }>) {
            state.messages.push({
                code: null,
                message_html: action.payload.message,
                level: action.payload.level ?? CartMessageLevelEnum.ERROR,
                pages_visible: action.payload.pagesVisible,
            });
        },
    },
});

export const {
    setIsLoading,
    setLoading,
    setBootstrapStatus,
    setAppMessages,
    clearAppMessages,
    addAppMessage
} = appSlice.actions;

export default appSlice;

// ------------------------- [ Thunks ] -------------------------

export const loadBootstrap = (cartUuid?: string, cartConfig: GetCartConfig = {
    do_validate: true,
    do_remove_erroneous_products: true,
    do_validate_shipping_restrictions: true,
    do_calculate_tax: true,
}): ThunkAction<Promise<Cart>> => (
    async (dispatch: ThunkDispatch, getState: () => GlobalState) => {
        // Get the cart from state if a cart UUID wasn't provided.
        // This is done for performance purposes when the cart already exists, such as when transitioning from CartPage to CheckoutPage.
        dispatch(setLoading({ key: LoadingKey.bootstrap, isLoading: true }));
        dispatch(setBootstrapStatus(BootstrapStatus.LOADING));

        const cart = cartUuid
            ? await dispatch(loadCart(cartUuid, cartConfig)) // Fetch cart but don't set it in state yet.
            : getState().cart;

        if (!cart) {
            throw new Error("Cart is null. The `cartUuid` argument is REQUIRED when cart does not exist in state.");
        }

        // If the cart is associated to a customer, we know the client is authenticated as the customer.
        if (cart.customer_id) {
            await dispatch(loadAddresses(cart.customer_id));
            await dispatch(loadCustomerPaymentMethods(cart.customer_id));
        }

        // Reload cart again if we can validate it.
        dispatch(setBootstrapStatus(BootstrapStatus.LOADED));
        dispatch(setLoading({ key: LoadingKey.bootstrap, isLoading: false }));
        console.debug("Successfully loaded bootstrap.", cartUuid);

        eventEmitter.emit(
            new UserSessionUpdatedEvent(cart),
        );

        return cart;
    }
);
